.calc-page {
  .qf-btn,
  .qf-btn:hover {
    background: #05f58f;
    color: #1a0352 !important;
    border-radius: 90px;
    border: solid 2px #05f58f !important;
    min-width: 150px;
    font-weight: 600;
    display: block;
  }
  .template-body {
    .heading {
      font-size: 24px;
      color: #fff;
      display: flex;
      justify-content: space-between;
      margin: 3rem 0;
      align-items: center;

      .title {
        font-size: 30px;
        font-weight: 600;
        max-width: 50%;
      }
      .quest-logo {
        width: 160px;
        max-width: 50%;

        img {
          max-width: 100%;
        }
      }
    }
    .body {
      padding: 3rem;
      background: #fff;
      border-radius: 0.5rem;
    }
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    .grp-name {
      padding-bottom: 1rem;
      font-size: 18px;
      font-weight: 600;
      color: #cb09a0;
    }
    .field {
      padding-bottom: 1rem;

      .label {
        font-weight: 600;
        margin-bottom: 0.25rem;
      }
      .info {
        font-weight: initial;
      }
    }
    .form {
      margin-bottom: 3rem;
    }
    .quote-result {
      margin-bottom: 1rem;

      .scenario {
        font-weight: 600;
        padding: 1rem;
        border-radius: 0.5rem;
        margin-bottom: 0.5rem;
      }

      .scenario-sm {
        font-weight: 600;
        text-align: center;
        display: block;
      }

      .head {
        font-weight: 600;
        background: #d1faf5;
        padding: 1rem;
        border-radius: 0.5rem;
        margin-bottom: 0.5rem;
      }
      .week {
        font-weight: 600;
        text-align: center;
        max-width: 120px;
        margin: 0 auto;
        display: block;
      }
      .row-label {
        font-weight: 600;
      }
      .treatment {
        border: 1px solid #e5e5e5;
        border-radius: 0.5rem;

        .calc-row {
          padding: 1rem;

          &:not(:last-child) {
            border-bottom: 1px solid #e5e5e5;
          }
        }
      }
      .val {
        border-radius: 0.25rem;
        text-align: center;
        padding: 0.25rem;
        max-width: 120px;
        margin: 0 auto;
        background: #fff;

        &.bold {
          font-weight: 600;
        }
        &.hl {
          background: #d1faf5;
        }
      }
    }
    .quote-result .sm {
      margin-bottom: 2rem;
    }

    .button-spacing {
      display: flex;
      justify-content: space-between;
    }

    .print-quote-btn {
      background: none;
    }

    .print-quote-btn:hover {
      background: #05f58f;
    }

    @media (min-width: 767.98px) {
      .quote-result.sm {
        display: none;
      }
    }
    @media (max-width: 767.98px) {
      .qf-btn {
        margin: 10px auto;
      }
      .heading .quest-logo {
        max-width: 120px;
      }
      .quote-result.lg {
        display: none;
      }
      .body {
        padding: 1.5rem;
      }
      .button-spacing {
        display: flex;
        flex-direction: column;
      }
    }
    @media (max-width: 1200px) {
      .heading {
        margin: 1.5rem 0;
        align-items: flex-start;

        .title {
          font-size: 18px;

          .dash {
            display: none;
          }
          .line-2 {
            display: block;
          }
        }
      }
    }
  }
}
