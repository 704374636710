/* Global Styles */
.quote-calc {
  font-family: Arial, sans-serif;
  background-color: #3d1c80;
  color: #333;
  padding: 20px 0;
}

.quote-calc .container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
}

/* Quote Form Section */
.quote-calc .quote-form {
  width: 60%;
}

.quote-calc .quote-form h2 {
  font-size: 20px;
  margin-bottom: 20px;
  color: #3d1c80; /* Changed from #1a0352 */
}

.quote-calc .form-group {
  margin-bottom: 15px;
}

.quote-calc .form-group label {
  display: block;
  font-size: 14px;
  color: #3d1c80; /* Changed from #1a0352 */
  margin-bottom: 5px;
}

/* Update the existing input styles */
.quote-calc .form-group input[type="text"],
.quote-calc .form-group input[type="number"],
.quote-calc .form-group input[type="email"],
.quote-calc .form-group select {
  width: 100%;
  padding: 8px 10px;
  border: 1px solid #999999; /* Darker border color */
  border-radius: 4px;
  font-size: 14px;
  text-align: left;
  background-color: #f5f5f5; /* Light gray background */
  color: #333; /* Darker text color */
}

/* Ensure placeholder text is also left-aligned and slightly darker */
.quote-calc .form-group input::placeholder {
  text-align: left;
  color: #666; /* Darker placeholder text */
}

/* Style for focus state */
.quote-calc .form-group input[type="text"]:focus,
.quote-calc .form-group input[type="number"]:focus,
.quote-calc .form-group input[type="email"]:focus,
.quote-calc .form-group select:focus {
  outline: none;
  border-color: #05dd80;
  box-shadow: 0 0 0 2px rgba(5, 221, 128, 0.1);
  background-color: #ffffff; /* White background when focused */
}

.input-split {
  display: flex;
  gap: 10px;
}

.input-split input {
  width: 50%;
}

/* Button Styles */
.term-options,
.cycle-options,
.finance-options {
  display: flex;
  gap: 5px;
}

.term-options button,
.cycle-options button,
.finance-options button {
  padding: 8px 12px;
  border: 1px solid #ccc;
  background-color: #f0f0f0;
  color: #333;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.term-options button.active,
.cycle-options button.active,
.finance-options button.active {
  background-color: #05dd80;
  color: white;
  border-color: #05dd80;
}

/* Quote Summary Section */
.quote-calc .quote-summary {
  position: sticky;
  top: 20px; /* Adjust this value to set where it sticks */
  width: 35%;
  background-color: #f0fff5;
  border: 2px solid #05dd80;
  border-radius: 10px;
  padding: 20px;
  height: fit-content;
  align-self: flex-start; /* Ensures it sticks to the top of its container */
}

.quote-calc .quote-summary h2 {
  font-size: 20px;
  color: #3d1c80; /* Changed from #05dd80 */
  margin-bottom: 15px;
}

.summary-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 14px;
}

.divider {
  margin-top: 15px;
  padding-top: 10px;
  border-top: 1px solid #ccc;
  width: 100%;
}
.summary-item.text-bold {
  font-weight: bold;
}
.info-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  background-color: #05dd80;
  color: white;
  border-radius: 50%;
  text-align: center;
  line-height: 16px;
  font-size: 12px;
  margin-left: 5px;
}

/* Add these new styles */
.input-row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 15px;
}

.input-column {
  flex: 1;
}

/* Responsive Design */
@media (max-width: 768px) {
  .quote-calc .container {
    flex-direction: column;
  }

  .quote-calc .quote-form,
  .quote-calc .quote-summary {
    width: 100%;
  }

  .quote-calc .quote-summary {
    position: static; /* Remove sticky positioning on small screens */
    margin-top: 20px;
  }

  .input-row {
    flex-direction: column;
    gap: 15px;
  }
}
.full-width-frame {
  width: 100%;
  margin-bottom: 15px;
}

.input-split {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.input-wrapper {
  width: 48%; /* Adjust as needed */
}

.input-wrapper .rectangle {
  width: 100%;
}

.text-wrapper {
  display: block;
  margin-bottom: 5px;
}

/* Ensure the input inside InputField takes full width */
.rectangle {
  width: 100%;
  box-sizing: border-box;
}

.spacer {
  height: 20px; /* Adjust this value to increase or decrease spacing */
}

.checkbox-row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.checkbox-column {
  flex: 1;
}

/* Adjust the existing styles for checkboxes if needed */
.checkbox-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #3d1c80;
  font-weight: 500;
}

.checkbox-text {
  font-size: 14px;
  color: #3d1c80;
  margin-left: 10px;
}

/* Keep the existing styles for the checkbox itself */
.checkbox-input {
  display: none;
}

.checkbox-custom {
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-radius: 4px;
  display: inline-block;
  transition:
    background-color 0.3s ease,
    border-color 0.3s ease;
}

.checkbox-input:checked + .checkbox-custom {
  background-color: #05dd80;
  border-color: #05dd80;
}

/* You might need to adjust these styles based on your InputField component implementation */
.checkbox-column .form-group {
  margin-bottom: 0;
}

/* New styles for radio buttons */
.radio-group {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  gap: 15px; /* Reduce gap between buttons */
}

.radio-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.radio-input {
  display: none;
}

.radio-custom {
  width: 40px;
  height: 40px;
  border: 2px solid #c0c2c3;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  font-size: 14px;
  background-color: #e5e5e5;
  color: #333;
}

.radio-custom:hover {
  background-color: #3d1c80;
  border-color: #3d1c80;
  color: white;
}

.radio-input:checked + .radio-custom {
  background-color: #05dd80;
  border-color: #05dd80;
  color: white;
}

.radio-text {
  font-size: 12px; /* Reduce font size */
  margin-top: 4px; /* Add some space between button and text */
}

/* Toggle switches */
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 160px; /* Adjust as needed */
  height: 34px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #c0c2c3; /* Change this line */
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #05dd80;
}

input:checked + .slider:before {
  transform: translateX(126px); /* Adjust based on your toggle width */
}

.slider-option {
  color: #333;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.4s;
}

.slider-option.left {
  left: 10px;
}

.slider-option.right {
  right: 10px;
}

input:checked + .slider .slider-option.left,
input:not(:checked) + .slider .slider-option.right {
  opacity: 0.5;
}

/* Style for the email quote button */
.email-quote-button {
  width: 100%;
  padding: 15px;
  background-color: #05dd80;
  color: white;
  border: none;
  border-radius: 0 0 10px 10px; /* Rounded corners only at the bottom */
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.email-quote-button:hover {
  background-color: #04b669;
}

.format-money {
  text-align: right;
  padding-right: 10px; /* Adjust padding as needed */
}

.format-money::before {
  content: "$"; /* Currency symbol for money */
  margin-right: 5px;
}

.format-percentage {
  text-align: right;
  padding-right: 10px; /* Adjust padding as needed */
}

.format-percentage::after {
  content: "%"; /* Percentage symbol */
  margin-left: 5px;
}

.quest-logo {
  width: 160px;
  max-width: 50%;
  margin: 0 auto;

  img {
    max-width: 100%;
  }
}
