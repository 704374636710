@media (min-width: 768px) {
  .visible-inline-md {
    display: inline-block !important;
  }
  .hidden-md {
    display: none !important;
  }
}
@media (max-width: 767px) {
  .visible-inline-sm {
    display: inline-block !important;
  }
  .hidden-sm {
    display: none !important;
  }
}
@media (max-width: 458px) {
  .powered-by-quest img {
    height: 16px;
  }
}
