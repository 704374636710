/* Global Styles */
.quote-calc * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.quote-calc {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.6;
  min-height: 100vh;
}

/* format styles */

.format-money {
  text-align: right;
  padding-right: 10px; /* Adjust padding as needed */
}

.format-money::before {
  content: "$"; /* Currency symbol for money */
  margin-right: 5px;
}

.format-percentage {
  text-align: right;
  padding-right: 10px; /* Adjust padding as needed */
}

.format-percentage::after {
  content: "%"; /* Percentage symbol */
  margin-left: 5px;
}

/* Container Styles */
.iphone {
  display: flex;
  justify-content: center;
  width: 100%;
  flex: 1;
  background-color: #ffffff;
  overflow-y: auto; /* Allow vertical scrolling */
}

.iphone .div {
  width: 100%;
  max-width: 390px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100%; /* Ensure it takes at least full height of the parent */
}

/* Navbar Styles */
.quote-calc .navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  position: relative;
  padding: 20px 0;
}

.quote-calc .navbar-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25%;
  position: relative;
  z-index: 2;
}

.quote-calc .ellipse,
.ellipse-2,
.ellipse-3,
.ellipse-4 {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-bottom: 5px;
  position: relative;
  z-index: 2;
}

.quote-calc .ellipse {
  background-color: #05dd80;
}

.solid {
  background-color: #05dd80 !important;
}

.ellipse-2,
.ellipse-3,
.ellipse-4 {
  border: 2px solid #05dd80;
  background-color: white;
}

.line {
  height: 2px;
  background-color: #05dd80;
  position: absolute;
  top: calc(
    20px + 7.5px
  ); /* 20px (top padding) + 7.5px (half of ellipse height) */
  left: 0;
  right: 0;
  z-index: 1;
}

.text-wrapper-6,
.text-wrapper-7,
.text-wrapper-8,
.text-wrapper-9 {
  font-size: 14px; /* Increased from 12px */
  font-weight: 700;
  color: #05dd80;
  text-align: center;
  margin-top: 5px;
}

/* Header Styles */
.text-wrapper-4 {
  font-size: 28px;
  font-weight: 700;
  color: #3d1c80; /* Changed from #140a29 */
  margin-bottom: 16px;
  line-height: 1.2;
}

.text-wrapper-3 {
  font-size: 22px;
  font-weight: 600;
  color: #008aff;
  margin-bottom: 32px;
}

/* Form Styles */
.frame {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 32px;
}

.frame-2 {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.text-wrapper {
  font-size: 14px;
  font-weight: 600;
  color: #3d1c80; /* Changed from #140a29 */
}

.rectangle {
  height: 48px;
  border: 1px solid #999999; /* Darker border color */
  border-radius: 8px;
  padding: 0 10px;
  text-align: left;
  font-size: 16px; /* Increase font size */
}

/* Ensure the input text doesn't overlap with the placeholder */
.rectangle::placeholder {
  position: relative;
  left: 5px; /* Adjust this value to fine-tune the position */
  top: 25%;
  transform: translateY(-50%);
  color: #999; /* Adjust color as needed */
}

.rectangle[type="text"] {
  padding-left: 20px; /* Should be slightly larger than the left position of the placeholder */
}

/* Dropdown Styles */
.rectangle-select {
  appearance: none; /* Remove default arrow */
  padding: 10px;
  background-color: #fff;
  border: 1px solid #7f7f7f;
  border-radius: 8px;
  cursor: pointer;
  transition: border-color 0.3s ease;
}

.rectangle-select:focus {
  border-color: #05dd80;
}

/* Button Styles */
.quote-calc .overlap {
  background-color: #05dd80;
  border-radius: 8px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.quote-calc .overlap:hover {
  background-color: #04c472;
}

.text-wrapper-2 {
  color: #ffffff; /* Changed from #3d1c80 for better contrast */
  font-size: 18px;
  font-weight: 700;
}

/* Footer Styles */
.quote-calc .group {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
}

.quote-calc .image {
  height: 21px;
  width: auto;
  margin-left: 10px;
}

.text-wrapper-5 {
  font-size: 12px;
  color: #000000;
}

/* Responsive Styles */
@media screen and (max-width: 480px) {
  .quote-calc .navbar {
    margin-bottom: 30px;
    padding: 15px 0;
  }

  .text-wrapper-4 {
    font-size: 24px;
  }

  .text-wrapper-3 {
    font-size: 20px;
  }

  .overlap {
    height: 44px;
  }

  .text-wrapper-2 {
    font-size: 16px;
  }

  .text-wrapper-6,
  .text-wrapper-7,
  .text-wrapper-8,
  .text-wrapper-9 {
    font-size: 12px; /* Increased from 10px for mobile */
  }

  .line {
    top: calc(15px + 7.5px); /* Adjust for smaller padding on mobile */
  }
}

.quote-calc .checkbox-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #3d1c80;
  font-weight: 500;
}

.quote-calc .checkbox-input {
  display: none;
}

.checkbox-custom {
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-radius: 4px;
  display: inline-block;
  margin-right: 10px;
  transition:
    background-color 0.3s ease,
    border-color 0.3s ease;
}

.quote-calc .checkbox-input:checked + .checkbox-custom {
  background-color: #05dd80;
  border-color: #05dd80;
}

.quote-calc .checkbox-text {
  font-size: 14px;
  color: #3d1c80;
  font-weight: bold;
}

/* Add this new style */
.quote-calc #root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Update the existing .rectangle class */
.quote-calc .rectangle {
  height: 48px;
  border: 1px solid #999999; /* Darker border color */
  border-radius: 8px;
  padding: 0 10px; /* Add padding for better usability */
  text-align: left; /* Ensure text aligns to the left */
  font-size: 16px; /* Increase font size */
}

/* Remove or comment out these lines if they exist */
/*
.rectangle::placeholder {
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
  color: #999;
}

.rectangle[type="text"] {
  padding-left: 20px;
}
*/

/* Add this new style for all input types */
.quote-calc input[type="text"],
.quote-calc input[type="number"],
.quote-calc input[type="email"],
.quote-calc select {
  text-align: left;
  padding: 0 10px;
  font-size: 16px; /* Increase font size */
}

/* Ensure placeholder text is also left-aligned */
.quote-calc input::placeholder {
  text-align: left;
}

.quest-logo {
  width: 160px;
  max-width: 50%;
  margin: 0 auto;

  img {
    max-width: 100%;
  }
}
