.qf-template {
  background: linear-gradient(180deg, #1a0352 0%, #4d2c9a 100%) no-repeat;
  min-height: 100vh;
  color: #1a0352;
  overflow: hidden;

  .template-header {
    background-color: #fff;
    padding: 1rem;

    .inner {
      color: #1a0352;
      max-width: 1400px;
      margin: 0 auto;
      display: flex;
      align-items: center;
    }
    .company {
      font-size: 24px;
      font-weight: 600;
    }
    .logo {
      margin-left: auto;
      height: 50px;
    }
  }
  .template-body {
    margin: 0 auto;
    max-width: 1200px;
  }

  @media (max-width: 768px) {
    .template-header {
      .company {
        font-size: 18px;
      }
      .logo {
        max-width: 200px;
      }
    }
  }
  @media (max-width: 1200px) {
    .template-body {
      margin: 0 2rem;
    }
  }
}
