.theme-wrapper {
  min-height: 100vh;
  background: #008aff;
}

.theme-v2 {
  background: #008aff;

  .sqz-calc_wrap {
    background: #008aff;
    border-radius: 0px;

    @media (max-width: 769px) {
      padding-bottom: 150px;
    }
  }

  .sqz-form_row {
    margin-bottom: 30px;
  }

  .sqz-calc_wrap {
    label {
      color: white;
      font-family: "GilroyMedium";
      font-weight: 500;

      strong {
        font-family: "GilroyBold";
      }

      &.error {
        margin: 0px;
        position: absolute;
        bottom: -25px;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
      }
    }
  }

  .form-control {
    border-color: #3d1c80;
    border-width: 2px !important;
    text-align: center !important;
    color: #3d1c80;
    font-family: "GilroySemibold";
  }

  .nice-select .list {
    border: solid 2px #3d1c80;

    li {
      text-align: center;
    }
  }

  .section-title {
    color: white;
    margin-bottom: 25px;
    font-family: "Gilroybold";
    font-size: 36px;
  }

  .section-desc {
    font-weight: 400;
    font-size: 15px;
  }

  .balloon-label {
    margin-left: 8px;

    img {
      width: 20px;
      vertical-align: text-bottom;
    }
  }

  .custom-radio-select {
    ul {
      max-width: 400px;
      margin: auto;

      li {
        padding: 0px;
        flex: 1;
        max-width: unset;
        width: unset;

        label.sqz-sel_btn {
          border-radius: 0px;
          background: white;
          color: #3d1c80;
          border: 2px solid #3d1c80;
          font-family: "GilroySemibold";
        }

        &:first-child {
          label.sqz-sel_btn {
            border-top-left-radius: 30px;
            border-bottom-left-radius: 30px;
          }
        }

        &:last-child {
          label.sqz-sel_btn {
            border-top-right-radius: 30px;
            border-bottom-right-radius: 30px;
          }
        }

        input:checked + label.sqz-sel_btn {
          background: #05dd80;
        }
      }
    }
  }

  .sqz-calc_wrap {
    .sqz-btn {
      width: unset;
      margin-bottom: 10px;
      font-family: "GilroySemibold";
      text-transform: none;
      font-size: 20px;

      &:hover {
        background: #01d77b;
      }
    }

    p {
      font-size: 16px;
      font-weight: 400;
      font-family: "GilroySemibold";
    }

    .sqz-loan_box {
      color: white;
      border: 0;
      padding: 50px 15px 150px;
      height: unset;
      position: relative;

      .sqz-box_inner {
        z-index: 2;
      }

      .bg-loan-box {
        position: absolute;
        top: 0px;
        height: 100%;
        width: 100%;
        z-index: 1;
      }

      h3 {
        color: white;
        font-size: 32px;
        margin-bottom: 50px;
        font-family: "GilroySemibold";
      }

      .sqz-loan_amount {
        color: white;
        font-size: 62px;
        margin-bottom: 10px;
        font-family: "GilroyBold";

        @media (max-width: 640px) {
          font-size: 42px;
        }
      }

      .sqz-loan_label {
        font-weight: 400;
        font-size: 22px;
        margin-bottom: 50px;
        font-family: "GilroyMedium";
      }
    }
  }
}
