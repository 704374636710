.toggle-switch {
  display: flex !important;
  border-radius: 30px !important;
  border: 1px solid #ddd !important;
  overflow: hidden !important;
  width: 100% !important;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1) !important;
}

.toggle-option {
  padding: 10px 20px !important;
  cursor: pointer !important;
  flex: 1 !important;
  text-align: center !important;
  color: #000 !important;
  background-color: #e5e5e5 !important;
  font-weight: 600 !important;
  position: relative !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  transition: background-color 0.3s ease, color 0.3s ease !important;
}

.toggle-option.active {
  background-color: #05dd80 !important; /* Highlight color */
  color: white !important;
}

.checkmark {
  position: absolute !important;
  left: 10px !important;
  font-size: 12px !important;
  font-weight: bold !important;
}
